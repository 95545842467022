import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { BenefitWithPlanInfo } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import type { Experiments } from '@wix/yoshi-flow-editor';
import settingsParams from '../../components/BookingsForm/settingsParams';
import {
  BusinessInfo,
  CartModal,
  CartModalStatus,
  Dialog,
  IncompleteFormSelectedSlot,
  PaymentOption,
  PlanWithDescription,
  ServicePaymentDetails,
  SettingsSubTab,
  SettingsTab,
  SummaryPaymentDetails,
  TFunction,
} from '../../types/types';
import {
  FormNestedSlot,
  FormSelectedSlot,
} from '../../types/formSelectedSlots';
import {
  getActiveSchedule,
  getServiceType,
  mapCatalogServiceToService,
  Service,
} from '../mappers/service.mapper';
import { ServiceType } from '@wix/bookings-uou-types';
import { BookingsDataCapsule } from '@wix/bookings-data-capsule';
import {
  Form,
  GetActiveFeaturesResponse,
} from '@wix/ambassador-services-catalog-server/types';
import { FormApi } from '../../api/FormApi';
import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { createDummyState } from '../dummies/dummy-data';
import {
  getDefaultPaymentOptionId,
  getFirstAvailablePaymentOption,
} from '../payment/payment';
import { FormView, Submission } from '@wix/forms-ui/types';
import {
  getFieldsValuesInStorage,
  setIsEcomInStorage,
} from '../storageFunctions';
import { EmptyStateErrorType, FormError } from '../../types/errors';
import { getErrorByType } from '../errors/errors';
import { CouponInfo } from '../../types/coupons';
import { DynamicPriceInfo } from '../../types/dynamicPrice';
import {
  BookingRequestKeyMappings,
  getFieldFromSchema,
} from '../mappers/form-submission.mapper';
import { mapServicePaymentDto } from '@wix/bookings-uou-mappers';
import { FormStatus } from '../../types/form-state';
import {
  SelectedPaymentOption,
  ContactDetails,
} from '@wix/ambassador-bookings-gateway/types';
import { getCurrentTimezone } from '../timezone/timezone';
import { ListEligibleMembershipsResponse } from '@wix/ambassador-memberships-spi-host/http';
import { renderNoIndexMetaTag } from '../seo/seo';
import { initiateDynamicPriceState } from './initiateDynamicPriceState';
import { ExperimentsConsts } from '../../consts/experiments';
import { getPageAPIData } from '../../api/PageAPIAdapter/pageApiAdapter';
import { isDayfulFlow } from '../validations/dayful';
import { BookingsLineItemOption } from '@wix/bookings-checkout-api';
import { isServiceExist } from '../validations/Validations';
import { createFormView } from '../mappers/service-form.mapper';
import { getServiceSlotIdentifier } from '..';

export type EditorContext = {
  isDummy: boolean;
  selectedSettingsTabId?: SettingsTab;
  selectedSettingsSubTabId?: SettingsSubTab;
};

export type FormState = {
  benefitsWithPlanInfo?: BenefitWithPlanInfo[];
  publicPlans?: PlanWithDescription[];
  isPricingPlanInstalled: boolean;
  isMemberAreaInstalled: boolean;
  isCart?: boolean;
  isMultiServicesAppointmentsEnable?: boolean;
  shouldShowCollapseForm?: boolean;
  collapseFormValues?: ContactDetails;
  couponInfo: CouponInfo;
  businessInfo: BusinessInfo;
  activeFeatures: GetActiveFeaturesResponse;
  memberDetails?: Member;
  errors: FormError[];
  editorContext: EditorContext;
  status: FormStatus;
  overrideDefaultFieldsValues?: boolean;
  dialog?: Dialog;
  isBookingsOnEcom: boolean;
  isDayful?: boolean;
  formInputs: FormInputs;
  cartModal?: CartModal;
  bookingsLineItemOptions?: BookingsLineItemOption[];
  pricingPlanDetails?: PaidPlans; // only for non ecom
  formSelectedSlot: FormSelectedSlot;
  serviceData: ServiceData;
};

export type ServiceData = {
  isSingleService: boolean;
  formSchema: FormView;
  form?: Form;
  cancellationPolicy?: string;
  summaryPaymentDetails: SummaryPaymentDetails;
  maxNumberOfParticipants: number;
  maxNumberOfParticipantsWithoutPP?: number;
  slotServices: SlotServices; // key is lineItemId
};

export type SlotServices = { [key: string]: SlotService }; // key is lineItemId

export type SlotService = {
  memberships?: ListEligibleMembershipsResponse;
  possiblePlans?: PlanWithDescription[];
  selectedPaymentOption: PaymentOption;
  selectedPaymentType: SelectedPaymentOption;
  service: Service;
  nestedSlot: FormNestedSlot;
  paymentDetails: ServicePaymentDetails;
  dynamicPriceInfo?: DynamicPriceInfo;
};

export type FormInputs = {
  numberOfParticipants: number;
  email?: string;
};

export async function createInitialState({
  t,
  flowApi,
  wixSdkAdapter,
  bookingsDataCapsule,
  formApi,
}: {
  t: TFunction;
  flowApi: ControllerFlowAPI;
  wixSdkAdapter: WixOOISDKAdapter;
  bookingsDataCapsule: BookingsDataCapsule;
  formApi: FormApi;
}): Promise<FormState> {
  const { settings, controllerConfig } = flowApi;

  if (wixSdkAdapter.isSSR()) {
    return {
      status: FormStatus.SSR,
    } as FormState;
  }

  try {
    const pageAPIData = await getPageAPIData({ flowAPI: flowApi });

    let isBookingsOnEcom = false;

    const isEcomFromSessionStorage = !!pageAPIData?.isEcom;
    isBookingsOnEcom =
      !!isEcomFromSessionStorage ||
      (await formApi.isBookingsOnEcom({
        onError: (error) => {
          throw error;
        },
      }));
    setIsEcomInStorage(wixSdkAdapter, isBookingsOnEcom.toString());

    if (
      wixSdkAdapter.isEditorMode() ||
      (wixSdkAdapter.isPreviewMode() &&
        !isServiceExist(pageAPIData?.formSelectedSlot))
    ) {
      const [catalogData, isPricingPlanInstalled, isMemberAreaInstalled] =
        await Promise.all([
          formApi.getCatalogData(),
          wixSdkAdapter.isPricingPlanInstalled().catch(() => false),
          wixSdkAdapter.isMemberAreaInstalled().catch(() => false),
        ]);

      if (!catalogData) {
        throw EmptyStateErrorType.INVALID_CATALOG_DATA;
      }

      return createDummyState({
        flowApi,
        businessInfo: catalogData.businessInfo,
        isMemberAreaInstalled,
        isPricingPlanInstalled,
        isBookingsOnEcom,
        isCart: await isCartEnabled({
          experiments: flowApi.experiments,
          wixSdkAdapter,
          businessInfo: catalogData!.businessInfo,
        }),
      });
    }

    if (!pageAPIData) {
      throw EmptyStateErrorType.INVALID_PAGE_API_DATA;
    }

    const {
      areCouponsAvailable,
      catalogData,
      benefitsWithPlanInfo,
      publicPlans,
      numberOfSessions,
      formSelectedSlot,
      pricingPlanDetails,
      memberships,
      isPricingPlanInstalled,
      isMemberAreaInstalled,
      errors,
      bookingsLineItemOptions,
      serviceOptionsAndVariants,
    } = await fetchInitialData({
      formApi,
      controllerConfig,
      wixSdkAdapter,
      formSelectedSlot: pageAPIData.formSelectedSlot,
      timezone: pageAPIData.timezone,
      isBookingsOnEcom,
      experiments: flowApi.experiments,
    });

    renderNoIndexMetaTag(wixSdkAdapter);

    const emptyStateError = getErrorByType({
      errorType: EmptyStateErrorType,
      errors,
    });

    if (emptyStateError) {
      throw emptyStateError.errorType;
    }

    const preFilledValues: Maybe<Submission> = await getFieldsValuesInStorage(
      wixSdkAdapter,
      bookingsDataCapsule,
      flowApi.experiments,
      BookingsQueryParams.FILLED_FIELDS,
    );

    const isFixFormUoUCheckboxLinkEnabled = flowApi.experiments.enabled(
      ExperimentsConsts.FixFormUoUCheckboxLink,
    );
    const isAlwaysShowComplexPhoneFieldEnabled = flowApi.experiments.enabled(
      ExperimentsConsts.AlwaysShowComplexPhoneField,
    );

    const isCart = await isCartEnabled({
      experiments: flowApi.experiments,
      wixSdkAdapter,
      businessInfo: catalogData!.businessInfo,
    });

    const {
      controllerConfig: { wixCodeApi },
      environment: { isPreview },
    } = flowApi;

    const isDayful = await isDayfulFlow(wixCodeApi, isPreview);

    const shouldShowCollapseForm = (bookingsLineItemOptions?.length! || 0) > 0;

    const shouldShowContactFields = !shouldShowCollapseForm;

    const isMultiServiceAppointmentEnabled = flowApi.experiments.enabled(
      ExperimentsConsts.MultiServiceAppointment,
    );

    const isCancellationPolicyChangeAPIEnabled = flowApi.experiments.enabled(
      ExperimentsConsts.CancellationPolicyChangeAPI,
    );

    const isPreventConflictsOnCartEnabled = flowApi.experiments.enabled(
      ExperimentsConsts.PreventConflictsOnCart,
    );

    const summaryPaymentDetails = {
      totalPrice: 0,
      payLater: 0,
    };

    const slotServicesArray: SlotService[] = (
      formSelectedSlot?.nestedSlots || []
    )
      .map((slot): SlotService => {
        const serviceSlotIdentifier = getServiceSlotIdentifier(slot);
        const serviceInfo = catalogData?.serviceList.find(
          (service) => service.service.service?.id === slot.serviceId,
        );

        if (!serviceInfo || !catalogData) {
          return {} as any;
        }

        const slotMemberships = memberships?.[serviceSlotIdentifier];

        const service = mapCatalogServiceToService({
          serviceInfo,
          formSelectedSlot: formSelectedSlot!,
          pricingPlanDetails,
          memberships: memberships?.[serviceSlotIdentifier]!,
          numberOfSessions: numberOfSessions[slot.serviceId],
          serviceId: slot.serviceId,
          isDynamicPriceEnabled: !!(
            serviceOptionsAndVariants?.[serviceSlotIdentifier] ||
            pageAPIData.dynamicPricePreSelection?.[serviceSlotIdentifier]
          ),
          bookingsLineItemOptions,
          isCart,
          isCancellationPolicyChangeAPIEnabled,
          isPreventConflictsOnCartEnabled,
        });

        const dynamicPriceInfo = initiateDynamicPriceState({
          service,
          dynamicPricePreSelection:
            pageAPIData.dynamicPricePreSelection?.[serviceSlotIdentifier],
          formApi,
          wixSdkAdapter,
          dateRegionalSettingsLocale:
            catalogData?.businessInfo.dateRegionalSettingsLocale!,
          serviceOptionsAndVariants:
            serviceOptionsAndVariants?.[serviceSlotIdentifier],
        });

        const isDynamicPricingCustomOptions = !!dynamicPriceInfo?.customOptions;

        const defaultPaymentOptionId = getDefaultPaymentOptionId({
          settings,
          servicePayment: service.payment,
          pricingPlanDetails,
          memberships: slotMemberships,
          isPricingPlanInstalled: isPricingPlanInstalled!,
          isDynamicPricingCustomOptions,
        });

        const numberOfParticipantsFieldId =
          service.formFromCatalog!.numberOfParticipants!.fieldId!;

        const selectedPaymentOption = getFirstAvailablePaymentOption({
          service,
          memberships: slotMemberships,
          pricingPlanDetails,
          isPricingPlanInstalled: isPricingPlanInstalled!,
          businessInfo: catalogData!.businessInfo,
          selectedPaymentOptionId: defaultPaymentOptionId,
          isDynamicPreferenceType: dynamicPriceInfo?.isDynamicPreferenceType!,
          t,
          settings,
          numberOfParticipants:
            preFilledValues && numberOfParticipantsFieldId
              ? Number(preFilledValues[numberOfParticipantsFieldId] || 1)
              : 1,
        });

        const paymentDetails = mapServicePaymentDto(
          serviceInfo.service,
        ) as ServicePaymentDetails;
        summaryPaymentDetails.totalPrice += paymentDetails.price;
        summaryPaymentDetails.payLater += paymentDetails.minCharge;

        const selectedPaymentType = getDefaultPaymentType(
          settings,
          service?.paymentTypes,
        );

        let possiblePlans: PlanWithDescription[] | undefined;

        if (publicPlans) {
          possiblePlans = getServicePlans(
            service.id,
            publicPlans,
            benefitsWithPlanInfo,
          );
        }

        return {
          service,
          memberships: slotMemberships,
          nestedSlot: slot,
          dynamicPriceInfo,
          selectedPaymentType,
          selectedPaymentOption,
          paymentDetails,
          ...(possiblePlans ? { possiblePlans } : {}),
        };
      })
      .filter((service) => Object.keys(service).length);

    if (!slotServicesArray.length) {
      throw new Error('bad data - missing services');
    }

    // if (only one service) {
    const slotServices: { [key: string]: SlotService } = {};
    slotServicesArray.reduce((acc, current) => {
      slotServices[getServiceSlotIdentifier(current.nestedSlot)] = current;
      return slotServices;
    }, slotServices);

    const formSchema = createFormView({
      slotServices,
      businessInfo: catalogData?.businessInfo!,
      t,
      serviceOptionsAndVariants,
      isAlwaysShowComplexPhoneFieldEnabled,
      isFixFormUoUCheckboxLinkEnabled,
      preFilledValues,
      shouldShowContactFields,
      formSelectedSlot,
    });

    const email = getEmailValue(formSchema!);

    const contactDetailsFromFirstBookingOnCart =
      (bookingsLineItemOptions?.length || 0) >= 1
        ? (bookingsLineItemOptions?.[0]?.contactDetails as ContactDetails)
        : undefined;

    const collapseFormValues = shouldShowCollapseForm
      ? contactDetailsFromFirstBookingOnCart
      : undefined;

    const numberOfParticipants = getNumberOfParticipantsValue(formSchema);

    const IsDeleteFullAddressFromCollapseFormValuesEnabled =
      flowApi.experiments.enabled(
        ExperimentsConsts.DeleteFullAddressFromCollapseFormValues,
      );

    if (IsDeleteFullAddressFromCollapseFormValuesEnabled) {
      delete collapseFormValues?.fullAddress;
    }

    return {
      serviceData: {
        slotServices,
        summaryPaymentDetails,
        isSingleService: slotServicesArray.length === 1,
        maxNumberOfParticipants:
          slotServicesArray[0].service.maxNumberOfParticipants,
        maxNumberOfParticipantsWithoutPP:
          slotServicesArray[0].service.maxNumberOfParticipantsWithoutPP,
        formSchema,
        form: catalogData?.serviceList[0].service.form,
        cancellationPolicy: slotServicesArray[0].service.cancellationPolicy,
      },
      formInputs: {
        email,
        numberOfParticipants,
      },
      activeFeatures: catalogData!.activeFeatures, // Todo: check if can remove it
      benefitsWithPlanInfo,
      businessInfo: catalogData!.businessInfo,
      formSelectedSlot: formSelectedSlot!,
      isPricingPlanInstalled: isPricingPlanInstalled!,
      isMemberAreaInstalled: isMemberAreaInstalled!,
      ...(catalogData!.businessInfo.isMultiServicesAppointmentsEnable &&
      isMultiServiceAppointmentEnabled
        ? { isMultiServicesAppointmentsEnable: true }
        : {}),
      ...(isCart
        ? {
            isCart,
            shouldShowCollapseForm,
            ...(shouldShowCollapseForm ? { collapseFormValues } : {}),
          }
        : {}),
      ...(pricingPlanDetails ? { pricingPlanDetails } : {}),
      errors,
      couponInfo: {
        areCouponsAvailable: areCouponsAvailable!,
        isCouponInputDisplayed: false,
      },
      editorContext: {
        isDummy: false,
      },
      status: FormStatus.INITIALIZING,
      overrideDefaultFieldsValues: false,
      dialog: undefined,
      isBookingsOnEcom,
      cartModal: {
        status: CartModalStatus.CLOSED,
        lineItems: [],
      },
      isDayful,
      ...(isPreventConflictsOnCartEnabled &&
      bookingsLineItemOptions?.length! > 0
        ? { bookingsLineItemOptions }
        : {}),
    };
  } catch (formError) {
    return {
      errors: [formError],
    } as FormState;
  }
}

export const getServicePlans = (
  serviceId: string,
  plans?: PlanWithDescription[],
  benefitsWithPlanInfo?: BenefitWithPlanInfo[],
): PlanWithDescription[] => {
  if (!plans || !benefitsWithPlanInfo || !benefitsWithPlanInfo.length) {
    return [];
  }

  const servicePlans = benefitsWithPlanInfo
    .filter((b) => b.benefit?.resourceIds?.includes(serviceId))
    .map((b) => b.planInfo?.id);

  return plans.filter((p) => servicePlans.includes(p.id));
};

export const getDefaultPaymentType = (
  settings: ControllerFlowAPI['settings'],
  paymentTypes: SelectedPaymentOption[],
): SelectedPaymentOption => {
  const defaultPaymentType = settings.get(settingsParams.defaultPaymentTime);
  if (paymentTypes.includes(defaultPaymentType)) {
    return defaultPaymentType;
  }

  if (paymentTypes.length === 1) {
    return paymentTypes[0];
  }

  return paymentTypes.filter(
    (paymentType) => paymentType !== SelectedPaymentOption.MEMBERSHIP,
  )[0];
};

const fetchInitialData = async ({
  formApi,
  controllerConfig,
  wixSdkAdapter,
  formSelectedSlot: uncompletedFormNestedSlot,
  timezone,
  isBookingsOnEcom,
  experiments,
  isMultiServiceAppointmentEnabled = true,
}: {
  formApi: FormApi;
  controllerConfig: IWidgetControllerConfig;
  wixSdkAdapter: WixOOISDKAdapter;
  formSelectedSlot: IncompleteFormSelectedSlot;
  timezone: string;
  isBookingsOnEcom: boolean;
  experiments: Experiments;
  isMultiServiceAppointmentEnabled?: boolean;
}) => {
  const formSelectedSlot = uncompletedFormNestedSlot as FormSelectedSlot;
  let errors: FormError[] = [];

  if (!isServiceExist(formSelectedSlot)) {
    errors = [...errors, { errorType: EmptyStateErrorType.INVALID_SERVICE_ID }];
    return {
      errors,
    };
  }

  const isFormPaymentOptionNewDropdownEnabled = experiments.enabled(
    ExperimentsConsts.FormPaymentOptionNewDropdown,
  );

  const user = controllerConfig.wixCodeApi.user.currentUser;

  const [isPricingPlanInstalled, isMemberAreaInstalled] = await Promise.all([
    wixSdkAdapter.isPricingPlanInstalled().catch(() => false),
    wixSdkAdapter.isMemberAreaInstalled().catch(() => false),
  ]);
  const isLoggedInUser = user.loggedIn;
  const shouldGetPricingPlanDetails = isPricingPlanInstalled && isLoggedInUser;
  const shouldGetPublicPlans =
    isPricingPlanInstalled &&
    (isFormPaymentOptionNewDropdownEnabled || isMultiServiceAppointmentEnabled);

  const [
    areCouponsAvailable,
    catalogData,
    benefitsWithPlanInfo,
    publicPlans,
    pricingPlanDetails,
    memberships,
    serviceOptionsAndVariants,
  ] = await Promise.all([
    formApi.areCouponsAvailableForService(),
    formApi.getCatalogData({
      formSelectedSlot,
      onError: () => {
        errors = [
          ...errors,
          { errorType: EmptyStateErrorType.INVALID_CATALOG_DATA },
        ];
      },
    }),
    formApi.getPricingPlansBenefitList(),
    shouldGetPublicPlans ? formApi.getPublicPlans() : undefined,
    shouldGetPricingPlanDetails && !isBookingsOnEcom
      ? formApi
          .getPricingPlanDetails({
            formSelectedSlot,
            onError: (errorType) => (errors = [...errors, { errorType }]),
          })
          .catch((e) => {
            errors = [...errors, e];
            return undefined;
          })
      : undefined,
    shouldGetPricingPlanDetails && isBookingsOnEcom && !wixSdkAdapter.isOwner()
      ? formApi
          .listMemberships({
            formSelectedSlot,
            onError: (errorType) => (errors = [...errors, { errorType }]),
          })
          .catch((e) => {
            errors = [...errors, e];
            return undefined;
          })
      : undefined,
    formApi
      .getOptionsAndVariantsData({
        formSelectedSlot,
        onError: (errorType) => (errors = [...errors, { errorType }]),
      })
      .catch((e) => {
        errors = [...errors, e];
        return undefined;
      }),
  ]);

  if (!catalogData) {
    return {
      errors,
    };
  }

  const isCartSpecEnabled = experiments.enabled(ExperimentsConsts.Cart);

  const shouldGetLineItemOptions = isCartSpecEnabled
    ? await wixSdkAdapter.isCartEnabled(catalogData.businessInfo)
    : false;

  const bookingsLineItemOptions = shouldGetLineItemOptions
    ? await formApi.getLineItemOptionsFromCart()
    : undefined;

  if (!isServiceExist(formSelectedSlot)) {
    errors = [
      ...errors,
      { errorType: EmptyStateErrorType.INVALID_SLOT_AVAILABILITY },
    ];
    return {
      errors,
    };
  }
  const numberOfSessions: { [key: string]: number } = {};
  const nestedSlot = formSelectedSlot.nestedSlots[0];
  const service = catalogData?.serviceList[0].service;
  const activeSchedule = getActiveSchedule(service!);
  const scheduleId = activeSchedule?.id!;
  const firstSessionStart = activeSchedule?.firstSessionStart;
  const lastSessionEnd = activeSchedule?.lastSessionEnd;

  const type = getServiceType(activeSchedule);
  numberOfSessions[service!.service!.id!] = 1;
  const isCourse = type === ServiceType.COURSE;

  if (isCourse) {
    if (!firstSessionStart) {
      errors = [
        ...errors,
        { errorType: EmptyStateErrorType.COURSE_WITHOUT_SESSIONS },
      ];
      return {
        errors,
      };
    }
    const [listSlots, courseAvailability, scheduleAvailability] =
      await Promise.all([
        lastSessionEnd
          ? formApi.getSlots({
              firstSessionStart: firstSessionStart!,
              lastSessionEnd,
              scheduleId,
              onError: (errorType) => (errors = [...errors, { errorType }]),
            })
          : {},
        !isBookingsOnEcom ? formApi.getAvailability({ scheduleId }) : undefined,
        isBookingsOnEcom
          ? formApi.getScheduleAvailability({ scheduleId })
          : undefined,
      ]);

    if (courseAvailability || scheduleAvailability) {
      const openSpots = isBookingsOnEcom
        ? scheduleAvailability?.openSpots
        : Number(courseAvailability!.capacity) -
          Number(courseAvailability!.totalNumberOfParticipants);
      const resolvedTimezone = getCurrentTimezone({
        pageApiTimezone: timezone,
        businessInfo: catalogData.businessInfo,
      });
      formSelectedSlot.openSpots = openSpots;
      formSelectedSlot.timezone = resolvedTimezone;

      if (nestedSlot) {
        nestedSlot.startDate = firstSessionStart;
        nestedSlot.endDate = lastSessionEnd!;
      }
    }

    numberOfSessions[nestedSlot.serviceId] =
      (listSlots as any)?.slots?.length || 1;
  }

  return {
    catalogData,
    benefitsWithPlanInfo,
    publicPlans,
    numberOfSessions,
    formSelectedSlot,
    pricingPlanDetails,
    memberships,
    isPricingPlanInstalled,
    isMemberAreaInstalled,
    errors,
    areCouponsAvailable,
    bookingsLineItemOptions,
    serviceOptionsAndVariants,
  };
};

const getNumberOfParticipantsValue = (formSchema: FormView): number => {
  const numberOfParticipants = getFieldFromSchema(
    formSchema,
    BookingRequestKeyMappings.NO_OF_PARTICIPANTS,
  )?.renderInfo?.displayProperties?.defaultValue;
  return (numberOfParticipants && Number(numberOfParticipants)) || 1;
};

const getEmailValue = (formSchema: FormView): string => {
  return getFieldFromSchema(formSchema, BookingRequestKeyMappings.EMAIL)
    ?.renderInfo?.displayProperties?.defaultValue;
};
async function isCartEnabled({
  experiments,
  wixSdkAdapter,
  businessInfo,
}: {
  experiments: Experiments;
  wixSdkAdapter: WixOOISDKAdapter;
  businessInfo: BusinessInfo;
}) {
  const isCartSpecEnabled = experiments.enabled(ExperimentsConsts.Cart);

  return (
    (isCartSpecEnabled && (await wixSdkAdapter.isCartEnabled(businessInfo))) ||
    false
  );
}
