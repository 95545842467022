import {
  FormState,
  SlotService,
  SlotServices,
} from '../../../../utils/state/initialStateFactory';
import { SubmissionResponse, Submission } from '@wix/forms-ui/types';
import { widgetDefaults } from '../../../../utils/bi/consts';
import {
  mapSubmissionToPartialBookRequest,
  mapFormSubmission,
  mapDynamicParticipantsNumber,
} from '../../../../utils/mappers/form-submission.mapper';
import { DialogType, CartFlow, CartModalStatus } from '../../../../types/types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { setFieldsValuesInStorage } from '../../../../utils/storageFunctions';
import { CreateActionParams } from '../actions';
import {
  bookingsContactInfoSaveSuccess,
  bookingsUouBookFlowBookingFormClickNext,
  bookingsUouBookFlowBookingFormClickNextFailure,
  bookingsPaymentMethodSelectionNextClicked,
  bookingsUouSessionAddedToCartModalInteractions,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { BookErrorType, GenericErrorType } from '../../../../types/errors';
import {
  CreateCheckoutResponse,
  CreateOrderResponse,
} from '@wix/ambassador-checkout/http';
import { FormStatus } from '../../../../types/form-state';
import {
  CreateBookingResponse,
  ContactDetails,
} from '@wix/ambassador-bookings-gateway/types';
import { SelectedVariants, ServiceType } from '@wix/bookings-uou-types';
import { CustomOption, DynamicPriceInfo } from '../../../../types/dynamicPrice';
import { ServiceOptionType } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { ExperimentsConsts } from '../../../../consts/experiments';
import { mapCartToBookingsLineItems } from '../../../../utils/mappers/cart.mapper';
import {
  AddToCartResponse,
  Cart,
  UpdateCartRequest,
} from '@wix/ambassador-ecom-v1-cart/types';
import { FormApi } from '../../../../api/FormApi';
import { getContactDetailsWithFieldIds } from '../../../../utils/mappers/collapseForm.mapper';
import {
  CartModalInteractionActionName,
  FormClickNextAction,
} from '../../../../types/biLoggerTypes';
import { Experiments } from '@wix/yoshi-flow-editor';
import { canCompleteBoooking } from '../../../../utils/validations/canBook';
import { findOverlappingResource } from '../../../../utils/cart-conflict/cartConflict';
import { mapToArray } from '../../../../utils';
import handleChoosePlanFlow from './handleChoosePlanFlow';
import { getSelectedServicesForBuyPlan } from '../../../../utils/payment/payment';

export type OnSubmit = (
  submission: SubmissionResponse,
  cartFlow?: CartFlow,
) => Promise<void>;

enum FlowType {
  OWNER,
  CHOOSE_PLAN,
  SINGLE_SESSION,
  PREVIEW,
  TEMPLATE,
}

export function createOnSubmitAction(
  actionFactoryParams: CreateActionParams,
): OnSubmit {
  return async (submissionResponse, cartFlow) => {
    const [state] = actionFactoryParams.getControllerState();
    const { wixSdkAdapter } = actionFactoryParams.context;
    const { status, serviceData, formSelectedSlot } = state;

    if (status === FormStatus.IDLE) {
      if (submissionResponse.state.valid) {
        switch (
          getCurrentFlow(state, serviceData.slotServices, wixSdkAdapter)
        ) {
          case FlowType.CHOOSE_PLAN:
            return handleChoosePlanFlow(
              actionFactoryParams,
              submissionResponse.submission,
              serviceData,
              formSelectedSlot,
            );
          case FlowType.OWNER:
            return handleOwnerFlow(actionFactoryParams);
          case FlowType.PREVIEW:
            return handlePreviewFlow(actionFactoryParams);
          case FlowType.TEMPLATE:
            return handleTemplateFlow(actionFactoryParams);
          case FlowType.SINGLE_SESSION:
            return handleSingleSessionFlow(
              actionFactoryParams,
              submissionResponse.submission,
              serviceData.slotServices,
              cartFlow,
              formSelectedSlot.v2Availability,
            );
        }
      }
    }
  };
}

function getCurrentFlow(
  state: FormState,
  slotServices: SlotServices,
  wixSdkAdapter: WixOOISDKAdapter,
): FlowType {
  const { isBookingsOnEcom } = state;

  if (wixSdkAdapter.isOwner() && !wixSdkAdapter.isPreviewMode()) {
    return FlowType.OWNER;
  } else if (getSelectedServicesForBuyPlan(slotServices)?.length) {
    return FlowType.CHOOSE_PLAN;
  } else if (wixSdkAdapter.isPreviewMode()) {
    return FlowType.PREVIEW;
  } else if (wixSdkAdapter.isTemplateMode() && isBookingsOnEcom) {
    return FlowType.TEMPLATE;
  } else {
    return FlowType.SINGLE_SESSION;
  }
}

export async function handleOwnerFlow({
  getControllerState,
  context: { t },
}: CreateActionParams) {
  const [, setState] = getControllerState();
  setState(
    getOwnerSubmitDialog({
      contentText: t('app.dialog.owner-submit.content'),
      confirmButtonText: t('app.dialog.owner-submit.confirm-button'),
    }),
  );
}
export async function handlePreviewFlow({
  getControllerState,
  context: { wixSdkAdapter },
}: CreateActionParams) {
  const [state] = getControllerState();
  const { isBookingsOnEcom } = state;
  if (isBookingsOnEcom) {
    await wixSdkAdapter.openEcomCheckoutPreviewModal();
  } else {
    await wixSdkAdapter.navigateToBookingsWithSuffix();
  }
}

export async function handleTemplateFlow({
  context: { wixSdkAdapter },
}: CreateActionParams) {
  await wixSdkAdapter.openEcomCheckoutTemplateModal();
}

export async function handleSingleSessionFlow(
  {
    getControllerState,
    internalActions: { errorHandlers },
    context: {
      biLogger,
      wixSdkAdapter,
      bookingsDataCapsule,
      formApi,
      reportError,
      experiments,
    },
  }: CreateActionParams,
  submission: Submission,
  slotServices: SlotServices,
  cartFlow?: CartFlow,
  v2Availability?: boolean,
) {
  // TODO: Support Multi Service
  const slotService = mapToArray<SlotService>(slotServices)[0];
  const [state, setState] = getControllerState();
  const {
    pricingPlanDetails,
    businessInfo,
    couponInfo,
    isBookingsOnEcom,
    isCart,
    shouldShowCollapseForm,
    collapseFormValues,
    isDayful,
    bookingsLineItemOptions,
    formSelectedSlot,
    serviceData,
  } = state;
  setState({
    status:
      isCart && cartFlow === CartFlow.CHECKOUT
        ? FormStatus.PROCESSING_CART_BOOK_NOW_REQUEST
        : FormStatus.PROCESSING_BOOK_REQUEST,
  });

  if (shouldShowCollapseForm && isBookingsOnEcom) {
    submission = {
      ...submission,
      ...getContactDetailsWithFieldIds({
        form: slotService.service.formFromCatalog,
        contactDetails: collapseFormValues,
      }),
    };
  }

  try {
    await setFieldsValuesInStorage(
      wixSdkAdapter,
      bookingsDataCapsule,
      experiments,
      submission,
    );
  } catch (e: any) {
    console.error('data capsule set value error', e);
  }

  if (isBookingsOnEcom) {
    if (
      !(await canCompleteBoooking({
        formApi,
        isDayful: isDayful!,
        wixSdkAdapter,
        service: slotService.service,
        activeFeatures: businessInfo.activeFeatures!,
      }))
    ) {
      setState({ status: FormStatus.IDLE });
      return;
    }

    const selectedMembership =
      slotService.memberships?.eligibleMemberships?.find(
        (membership) => membership?.id === slotService.selectedPaymentOption.id,
      );

    const isAddTimeZoneToContactDetailsEnabled = experiments.enabled(
      ExperimentsConsts.AddTimeZoneToContactDetails,
    );

    try {
      const {
        contactDetails,
        additionalFields,
        numberOfParticipants,
        sendSmsReminder,
      } = mapFormSubmission({
        submission,
        formSchema: serviceData.formSchema,
        businessInfo,
        collapseFormValues: shouldShowCollapseForm
          ? collapseFormValues
          : undefined,
        timezone: isAddTimeZoneToContactDetailsEnabled
          ? slotService?.nestedSlot?.timezone
          : undefined,
      });
      const { participantsChoices, totalParticipants } =
        mapDynamicParticipantsNumber(
          slotService.dynamicPriceInfo?.selectedVariants,
        );

      let dynamicPriceBIReportParams;
      if (slotService.dynamicPriceInfo?.serviceOptionsAndVariants) {
        dynamicPriceBIReportParams = getDynamicPriceBIReportParams(
          slotService.dynamicPriceInfo!,
        );
      }

      if (
        isCart &&
        bookingsLineItemOptions &&
        slotService?.service.type === ServiceType.INDIVIDUAL
      ) {
        const overlappingResource = findOverlappingResource(
          formSelectedSlot.nestedSlots,
          bookingsLineItemOptions,
        );
        if (overlappingResource) {
          errorHandlers.addError(
            GenericErrorType.OVERLAPPING_SLOTS_ON_CART_ERROR,
            { staffMember: overlappingResource },
          );
          setState({ status: FormStatus.IDLE });
          return;
        }
      }

      const {
        createCheckoutResponse,
        createBookingResponse,
        addToCurrentCartResponse,
      } = await formApi.checkoutBooking({
        service: slotService.service,
        slot: slotService.nestedSlot!,
        v2Availability,
        contactDetails,
        additionalFields,
        sendSmsReminder,
        appliedCoupon: couponInfo.appliedCoupon,
        selectedMembership,
        selectedPaymentType: slotService.selectedPaymentType,
        ...(participantsChoices ? { participantsChoices } : {}),
        ...(totalParticipants ? { totalParticipants } : {}),
        ...(!slotService.dynamicPriceInfo?.serviceOptionsAndVariants
          ? { numberOfParticipants }
          : {}),
        isCart,
        ...(isCart && cartFlow === CartFlow.CHECKOUT
          ? { cartFlow: CartFlow.CHECKOUT }
          : {}),
        paymentDetails: slotService.paymentDetails,
        onError: (error) => {
          errorHandlers.addError(error, { numberOfParticipants });
          biLogger?.report(
            bookingsUouBookFlowBookingFormClickNextFailure({
              errorReason: error,
            }),
          );
        },
        country: businessInfo.countryCode!,
      });

      const biLoggerData = {
        smsNotificationRequest: sendSmsReminder,
        bookingId: createBookingResponse?.booking?.id,
        ...(dynamicPriceBIReportParams ? dynamicPriceBIReportParams : {}),
      };

      if (
        isCart &&
        addToCurrentCartResponse &&
        !addToCurrentCartResponse?.cart
      ) {
        setState({ status: FormStatus.IDLE });
        return;
      } else {
        if (
          createCheckoutResponse &&
          createBookingResponse &&
          !isSubmitValid(createCheckoutResponse, createBookingResponse)
        ) {
          setState({ status: FormStatus.IDLE });
          return;
        }

        biLogger?.report(
          bookingsUouBookFlowBookingFormClickNext({
            ...biLoggerData,
            ...(isCart ? { action: FormClickNextAction.AddToCart } : {}),
          }),
        );
      }

      const isDayfulOverrideEcomContinueShoppingUrlEnabled =
        experiments.enabled(
          ExperimentsConsts.DayfulOverrideEcomContinueShoppingUrl,
        );

      let continueShoppingUrl = '';

      if (isDayfulOverrideEcomContinueShoppingUrlEnabled) {
        const isFixWidgetsNavigationAfterCheckoutWasRemovedEnabled =
          experiments.enabled(
            ExperimentsConsts.FixWidgetsNavigationAfterCheckoutWasRemoved,
          );
        const queryParams = wixSdkAdapter.getUrlQueryParams();
        if (isDayful) {
          continueShoppingUrl =
            queryParams.origin ||
            `${await wixSdkAdapter.getBookOnlineFullUrl(
              isFixWidgetsNavigationAfterCheckoutWasRemovedEnabled,
            )}?dayful=${queryParams.dayful}`;
        }
      }

      if (addToCurrentCartResponse?.cart) {
        const lineItems = addToCurrentCartResponse?.cart?.lineItems || [];
        if (lineItems.length === 1) {
          await updateCartWithMemberDetails({
            addToCurrentCartResponse,
            contactDetails,
            formApi,
            country: businessInfo.countryCode!,
            experiments,
          });
        }
        if (
          wixSdkAdapter.getUrlQueryParamValue(
            BookingsQueryParams.CONTINUE_SHOPPING_URL,
          )
        ) {
          setState({
            cartModal: {
              status: CartModalStatus.OPEN,
              lineItems,
              biLoggerData,
            },
            status: FormStatus.IDLE,
          });
          await wixSdkAdapter.reloadCart();
          biLogger?.report(
            bookingsUouSessionAddedToCartModalInteractions({
              ...biLoggerData,
              actionName: CartModalInteractionActionName.Load,
            }),
          );
        } else {
          await wixSdkAdapter.navigateAfterAddToCart();
        }
      } else {
        if (createCheckoutResponse && isOnlineFlow(createCheckoutResponse)) {
          return wixSdkAdapter.navigateToEcomCheckoutPage({
            checkoutId: createCheckoutResponse!.checkout!.id!,
            ...(continueShoppingUrl ? { continueShoppingUrl } : {}),
          });
        } else {
          return wixSdkAdapter.navigateToEcomThankYouPage({
            orderId: createCheckoutResponse!.orderId!,
            ...(continueShoppingUrl ? { continueShoppingUrl } : {}),
          });
        }
      }
    } catch (error) {
      errorHandlers.addError(error as BookErrorType);
      reportError(error as BookErrorType);
      setState({ status: FormStatus.IDLE });
      biLogger?.report(
        bookingsUouBookFlowBookingFormClickNextFailure({
          errorReason: error as Maybe<string>,
        }),
      );
    }
  } else {
    const isV1ShutdownEnabled = experiments.enabled(
      ExperimentsConsts.V1Shutdown,
    );

    if (isV1ShutdownEnabled) {
      setState({ status: FormStatus.IDLE });
      return wixSdkAdapter.openUoUPremiumModal(
        slotService?.service.type,
        'bookings-form',
      );
    }

    const selectedPlan = pricingPlanDetails?.plans?.find(
      (plan) =>
        plan?.paidPlan?.orderId === slotService.selectedPaymentOption.id,
    );
    try {
      const { formInfo, sendSmsReminder } = mapSubmissionToPartialBookRequest(
        submission,
        slotService.service,
        serviceData.formSchema,
        businessInfo,
        slotService?.nestedSlot?.timezone,
      );
      const bookingResponse = await formApi.book({
        service: slotService.service,
        formInfo,
        formSelectedSlot,
        selectedPlan,
        sendSmsReminder,
        appliedCoupon: couponInfo.appliedCoupon,
        onError: (error) => {
          errorHandlers.addError(error);
          biLogger?.report(
            bookingsPaymentMethodSelectionNextClicked({
              errorMessage: JSON.stringify(error),
            }),
          );
        },
      });

      if (!bookingResponse?.booking) {
        setState({ status: FormStatus.IDLE });
        return;
      }

      biLogger?.report(
        bookingsContactInfoSaveSuccess({
          smsNotificationRequest: sendSmsReminder,
        }),
      );

      delete bookingResponse.booking?.formInfo?.additionalFields;
      delete bookingResponse.booking?.formInfo?.customFormFields;

      return wixSdkAdapter.navigateToBookingsCheckout(
        bookingResponse.booking! as any,
        widgetDefaults.pageName,
      );
    } catch (error) {
      errorHandlers.addError(error as BookErrorType);
      setState({ status: FormStatus.IDLE });
      biLogger?.report(
        bookingsPaymentMethodSelectionNextClicked({
          errorMessage: JSON.stringify(error),
        }),
      );
      reportError(error as BookErrorType);
    }
  }
}

async function updateCartWithMemberDetails({
  addToCurrentCartResponse,
  contactDetails,
  formApi,
  country,
  experiments,
}: {
  addToCurrentCartResponse?: AddToCartResponse;
  contactDetails: ContactDetails;
  formApi: FormApi;
  country: string;
  experiments: Experiments;
}) {
  const cartLineItems = addToCurrentCartResponse?.cart?.lineItems || [];
  const bookingsLineItems = mapCartToBookingsLineItems(cartLineItems);
  const isFirstBookingItemOnCart = bookingsLineItems.length === 1;
  if (isFirstBookingItemOnCart) {
    const isSendAddressToEcomCheckoutAndCartEnabled = experiments.enabled(
      ExperimentsConsts.SendAddressToEcomCheckoutAndCart,
    );
    const cartInfo: Cart = {
      id: addToCurrentCartResponse?.cart?.id,
      buyerInfo: {
        email: contactDetails.email ?? '',
      },
      contactInfo: {
        ...(isSendAddressToEcomCheckoutAndCartEnabled &&
        contactDetails.fullAddress &&
        country
          ? { address: { ...contactDetails.fullAddress, country } }
          : {}),
        contactDetails: formApi.mapContactDetails(contactDetails),
      },
    };
    const updateCartRequest: UpdateCartRequest = {
      cartInfo,
    };
    await formApi.updateCart(updateCartRequest);
  }
}

function isOnlineFlow(
  checkoutResponse: CreateCheckoutResponse | CreateOrderResponse,
): checkoutResponse is CreateCheckoutResponse {
  return (checkoutResponse as CreateCheckoutResponse)?.checkout !== undefined;
}

function getOwnerSubmitDialog({
  contentText,
  confirmButtonText,
}: {
  contentText: string;
  confirmButtonText: string;
}): Partial<FormState> {
  return {
    dialog: {
      type: DialogType.OwnerSubmit,
      props: {
        isOpen: true,
        contentText,
        confirmButtonText,
      },
    },
  };
}

export function getQueryParams(wixSdkAdapter: WixOOISDKAdapter): {
  [key: string]: string;
} {
  return wixSdkAdapter.getUrlQueryParams();
}
const isSubmitValid = (
  createCheckoutResponse: CreateCheckoutResponse | CreateOrderResponse,
  createBookingResponse: CreateBookingResponse,
) => {
  if (!createBookingResponse?.booking?.id) {
    return false;
  }

  if (isOnlineFlow(createCheckoutResponse)) {
    return createCheckoutResponse.checkout?.id !== undefined;
  }

  return createCheckoutResponse?.orderId !== undefined;
};

const getDynamicPriceBIReportParams = (
  dynamicPriceInfo: DynamicPriceInfo,
): {
  price_type?: ServiceOptionType;
  numOfOptions?: number;
  dynamic_price_participants_json?: string;
} => {
  const dynamicPriceType = dynamicPriceInfo?.selectedPreferences?.[0].type;
  const isCustomType = dynamicPriceType === ServiceOptionType.CUSTOM;
  const dynamic_price_participants_json = isCustomType
    ? JSON.stringify(
        dynamicPriceInfo.selectedVariants?.map(
          (selectedVariant: SelectedVariants) => {
            const option = dynamicPriceInfo?.customOptions?.find(
              (customOption: CustomOption) =>
                customOption.optionId ===
                selectedVariant.choices?.[0]?.optionId,
            )?.options?.[0];
            return (
              option && {
                label: option?.title,
                amount: option?.price,
                num_participants: selectedVariant.numberOfParticipants,
              }
            );
          },
        ),
      )
    : '';
  return {
    price_type: dynamicPriceType,
    numOfOptions:
      dynamicPriceInfo?.serviceOptionsAndVariants?.options?.values?.length,
    dynamic_price_participants_json,
  };
};
